@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.swiper-container {
  width: calc(100% - 100px);
  height: 100vh;
  margin-left: 0; }
  @media screen and (max-width: 991px) {
    .swiper-container {
      width: 100%;
      height: 100vh;
      transition: height 1s ease; } }

.swiper-slide {
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .swiper-slide-active .slide__inner {
    transform: translateX(0px); }
  .swiper-slide-prev .slide__inner {
    transform: translateX(50%); }
  .swiper-slide-next .slide__inner {
    transform: translateX(-50%); }
  .swiper-slide .slide__inner {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 1.5s ease;
    position: relative; }
    .swiper-slide .slide__inner--logo .slider__logo {
      max-width: calc(1030 / 1803 * 100%);
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      @media screen and (max-width: 768px) {
        .swiper-slide .slide__inner--logo .slider__logo {
          max-width: 80%; } }
      @media screen and (max-width: 568px) {
        .swiper-slide .slide__inner--logo .slider__logo {
          max-width: 90%; } }
      .swiper-slide .slide__inner--logo .slider__logo img {
        width: 100%;
        max-width: 100%;
        height: auto;
        display: block; }
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          .swiper-slide .slide__inner--logo .slider__logo img.svg {
            display: none; } }
        .swiper-slide .slide__inner--logo .slider__logo img.ie {
          display: none; }
          @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            .swiper-slide .slide__inner--logo .slider__logo img.ie {
              display: block; } }

.p-mv {
  display: flex;
  position: relative; }
  .p-mv__bar {
    width: 100px;
    background-color: #fcb72b;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center; }
    @media screen and (max-width: 991px) {
      .p-mv__bar {
        background-color: transparent;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        flex-direction: row;
        justify-content: center; } }
  .p-mv__list {
    list-style-type: none;
    padding-left: 0; }
    @media screen and (max-width: 991px) {
      .p-mv__list {
        display: flex;
        justify-content: center; } }
    .p-mv__list > li {
      text-align: center;
      margin-bottom: 20px; }
      @media screen and (max-width: 991px) {
        .p-mv__list > li {
          margin: 0 20px 0 0; } }
      .p-mv__list > li:last-child {
        margin-bottom: 0; }
        @media screen and (max-width: 991px) {
          .p-mv__list > li:last-child {
            margin: 0; } }
      .p-mv__list > li.current a {
        color: #000000; }
        @media screen and (max-width: 991px) {
          .p-mv__list > li.current a {
            color: #FFFFFF; } }
        .p-mv__list > li.current a:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          background-color: #000000; }
          @media screen and (max-width: 991px) {
            .p-mv__list > li.current a:after {
              background-color: #FFFFFF; } }
      .p-mv__list > li a {
        display: block;
        font-size: 18px;
        font-family: "Poppins",sans-serif;
        font-weight: 600;
        color: #795858;
        position: relative; }
        @media screen and (max-width: 991px) {
          .p-mv__list > li a {
            color: #FFFFFF; } }

.p-about__heading {
  font-size: 36px !important; }
  @media screen and (max-width: 991px) {
    .p-about__heading {
      font-size: 32px !important; } }
  @media screen and (max-width: 568px) {
    .p-about__heading {
      font-size: 28px !important; } }
  .p-about__heading > small {
    display: block;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: 0em;
    line-height: 1.23;
    margin: 0 0 25px; }
  @media screen and (max-width: 991px) {
    .p-about__heading br {
      display: none; } }

.p-feature__card {
  margin-bottom: -30px; }

.p-feature__item {
  margin-bottom: 30px; }

.p-feature__title {
  margin: 0;
  font-size: 18px; }
  .p-feature__title > a:hover {
    color: #000000; }

.p-feature__btn {
  position: absolute;
  top: 0;
  right: 0; }
  @media screen and (max-width: 991px) {
    .p-feature__btn {
      position: static;
      margin-top: 40px;
      text-align: center; } }

.p-feature .container {
  position: relative; }

.p-business__card {
  margin-bottom: -30px; }

.p-business__item {
  margin-bottom: 30px;
  margin-top: 0; }

.p-business__title {
  margin: 0;
  font-size: 24px; }
  @media screen and (max-width: 991px) {
    .p-business__title {
      font-size: 18px; } }
  @media screen and (max-width: 768px) {
    .p-business__title {
      font-size: 24px; } }
  @media screen and (max-width: 568px) {
    .p-business__title {
      font-size: 18px; } }
  .p-business__title > a {
    color: #000000; }

.p-works__heading {
  margin-bottom: 40px; }

.p-works__txt {
  text-align: center; }
  @media screen and (max-width: 600px) {
    .p-works__txt {
      text-align: left; }
      .p-works__txt br {
        display: none; } }

.p-works__btn {
  text-align: center;
  margin-top: 50px; }

.p-works__gallery {
  margin-left: -25px;
  margin-right: -25px; }
  @media screen and (max-width: 991px) {
    .p-works__gallery {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: -30px; } }
  .p-works__gallery-row {
    padding-left: 25px;
    padding-right: 25px; }
    @media screen and (max-width: 991px) {
      .p-works__gallery-row {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px; } }
    @media screen and (max-width: 991px) {
      .p-works__gallery-row:last-child {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        justify-content: space-between; } }
    @media screen and (max-width: 991px) {
      .p-works__gallery-row:last-child .p-works__gallery-rect {
        width: calc(50% - 15px);
        height: 0;
        padding-top: 47.1%;
        margin: 0; } }
    @media screen and (max-width: 479px) {
      .p-works__gallery-row:last-child .p-works__gallery-rect {
        padding-top: 44.9%; } }
  .p-works__gallery-rect {
    width: 100%;
    height: 275px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px; }
    @media screen and (max-width: 991px) {
      .p-works__gallery-rect {
        height: 0;
        margin-bottom: 30px;
        padding-top: 100%; } }
    .p-works__gallery-rect:last-child {
      margin-bottom: 0; }
    .p-works__gallery-rect--long {
      height: 488px; }
      @media screen and (max-width: 991px) {
        .p-works__gallery-rect--long {
          height: 0;
          padding-top: 100%; } }

.p-project__container {
  padding: 0; }

.p-project__img {
  width: 100%;
  height: 466px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 50px; }
  @media screen and (max-width: 991px) {
    .p-project__img {
      height: 350px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 568px) {
    .p-project__img {
      height: 250px; } }

.p-staff__gallery {
  margin-bottom: -50px; }

.p-staff__block {
  margin-bottom: 50px; }

.p-staff__img {
  border-radius: 50%;
  overflow: hidden;
  max-width: 366px;
  margin: 0 auto 40px; }
  @media screen and (max-width: 568px) {
    .p-staff__img {
      max-width: 100%;
      margin-bottom: 10px; } }
  .p-staff__img img {
    width: 100%;
    display: block;
    line-height: 1; }

.p-staff__name {
  font-size: 24px;
  font-weight: 700;
  text-align: center; }
  @media screen and (max-width: 568px) {
    .p-staff__name {
      font-size: 18px; } }
